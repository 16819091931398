<template>
    <UnitList :isSelect="false" />
</template>

<script>
import { defineComponent } from "vue";
import UnitList from "@/components/UnitList";

export default defineComponent({
    components: {
        UnitList,
    },
});
</script>
